$(function() {
	// Validation
	$("#login-form").validate({
		// Rules for form validation
		rules : {
			hasid : {
				required : true,
			},
			correo : {
				required : true,
				email: true
			}
		},
		errorElement: 'span',
        errorClass: 'error-datos',

		// Messages for form validation
		messages : {
			hasid : {
				required : 'Por favor inserte su clave de reservación',
				alias : 'Por favor inserte una clave de reservación válida'
			},
			correo : {
				required : 'Por favor inserte su correo'
			}
		},

		// Do not change code below
		errorPlacement : function(error, element) {
			error.insertAfter(element.parent());
		}
	});
});